// order: in-game appearance according to the Capture List
export const captures : { [key: string]: string } = {
  Frog                     : 'Frog',
  ElectricWire             : 'Spark pylon',
  KuriboWing               : 'Paragoomba',
  Wanwan                   : 'Chain Chomp',
  WanwanBig                : 'Big Chain Chomp',
  BreedaWanwan             : 'Broode\'s Chain Chomp',
  TRex                     : 'T-Rex',
  Fukankun                 : 'Binoculars',
  Killer                   : 'Bullet Bill',
  Megane                   : 'Moe-Eye',
  Cactus                   : 'Cactus',
  Kuribo                   : 'Goomba',
  BossKnuckleHand          : 'Knucklotec\'s Fist',
  BazookaElectric          : 'Mini Rocket',
  Kakku                    : 'Glydon',
  JugemFishing             : 'Lakitu',
  Fastener                 : 'Zipper',
  Pukupuku                 : 'Cheep Cheep',
  GotogotonLake            : 'Puzzle Part (Lake Kingdom)',
  PackunPoison             : 'Poison Piranha Plant',
  Senobi                   : 'Uproot',
  FireBros                 : 'Fire Bro',
  Tank                     : 'Sherm',
  Gamane                   : 'Coin Coffer',
  Tree                     : 'Tree',
  RockForest               : 'Boulder',
  FukuwaraiFacePartsKuribo : 'Picture Match Part (Goomba)',
  Imomu                    : 'Tropical Wiggler',
  Guidepost                : 'Pole',
  Manhole                  : 'Manhole',
  Car                      : 'Taxi',
  Radicon                  : 'RC Car',
  Byugo                    : 'Ty-foo',
  Yukimaru                 : 'Shiverian Racer',
  PukupukuSnow             : 'Cheep Cheep (Snow Kingdom)',
  Hosui                    : 'Gushen',
  Bubble                   : 'Lava Bubble',
  HackFork                 : 'Volbonan',
  HammerBros               : 'Hammer Bro',
  CarryMeat                : 'Meat',
  PackunFire               : 'Fire Piranha Plant',
  Tsukkun                  : 'Pokio',
  Statue                   : 'Jizo',
  StatueKoopa              : 'Bowser statue',
  KaronWing                : 'Parabones',
  KillerMagnum             : 'Banzai Bill',
  Bull                     : 'Chargin\' Chuck',
  Koopa                    : 'Bowser',
  AnagramAlphabetCharacter : 'Letter',
  GotogotonCity            : 'Puzzle Part (Metro Kingdom)',
  FukuwaraiFacePartsMario  : 'Picture Match Part (Mario)',
  Yoshi                    : 'Yoshi',
}
