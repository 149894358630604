import { render, staticRenderFns } from "./scenarios-modal.vue?vue&type=template&id=1bed239b&"
import script from "./scenarios-modal.ts?vue&type=script&lang=ts&"
export * from "./scenarios-modal.ts?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports