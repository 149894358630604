import { render, staticRenderFns } from "./commands.vue?vue&type=template&id=499b57ce&"
import script from "./commands.ts?vue&type=script&lang=ts&"
export * from "./commands.ts?vue&type=script&lang=ts&"
import style0 from "./commands.vue?vue&type=style&index=0&id=499b57ce&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports